/*!
 * boatvertizer/caravan Embed 1.0.3 - https://boatvertizer.com
 * (c) 2022 ayon GmbH
 */

/**
 * Configuration
 */
let errors = [];

const platform = {
  name: 'boatvertizer',
  version: 'v1',
  url: 'https://embed.boatvertizer.com/'
}

const container = document.getElementById(platform.name);

if (!container) {
  errors.push("No valid container found");
} else if (!container.dataset.token || container.dataset.token.length != 32) {
  errors.push("No token provided");
}

/**
 * Configuration
 */
import Postmate from "postmate";
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";
import PhotoSwipe from "photoswipe/dist/photoswipe.esm.js";
import style from "bundle-text:photoswipe/dist/photoswipe.css";

if (errors.length == 0) {
  const iframeOffset =
    container.dataset.offset === undefined ? 80 : container.dataset.offset;
  const options = {
    url:
      container.dataset.url === undefined
        ? platform.url + platform.version + '/'
        : container.dataset.url,
    mode:
      container.dataset.mode === undefined ? "prod" : container.dataset.mode,
    name: platform.name,
    classList: [platform.name],
    container: container,
    token: container.dataset.token,
    language: container.dataset.language,
  };

  Postmate.debug = options.mode === "dev" ? true : false;
  document.head.insertAdjacentHTML(
    "beforeend",
    `<style>.${platform.name} {width:100%;border:none}</style>`
  );
  // Kick off the handshake with the iFrame
  const handshake = new Postmate({
    container: options.container, // Element to inject frame into
    url: options.url, // Page to load, must have postmate.js. This will also be the origin used for communication.
    name: options.name, // Set Iframe name attribute. Useful to get `window.name` in the child.
    classListArray: options.classList, //Classes to add to the iframe via classList, useful for styling.
    model: {
      token: options.token,
      language: options.language,
      mode: options.mode,
      platform: platform,
    },
  });

  document.head.insertAdjacentHTML("beforeend", `<style>${style}</style>`);

  // When parent <-> child handshake is complete, data may be requested from the child
  handshake.then((child) => {
    // Fetch the height property in child.html and set it to the iFrames height
    child
      .get("height")
      .then((height) => (child.frame.style.height = `${height}px`));

    child.on("updateHeight", (height) => {
      child.frame.style.height = `${height}px`;
    });

    child.on("openGallery", (data) => {
      const lightbox = new PhotoSwipeLightbox({
        dataSource: data.items,
        pswpModule: PhotoSwipe,
      });
      lightbox.init();
      lightbox.loadAndOpen(data.active);
    });

    let scrollPosition = window.scrollY;
    child.on("updateScrollPosition", (position) => {
      switch (position) {
        case "last":
          window.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
          break;
        default:
          scrollPosition = window.scrollY;
          window.scrollTo({
            top: container.offsetTop - iframeOffset,
            behavior: "auto",
          });
      }
    });
  });
}

if (errors.length > 0) {
  console.warn(`${platform.name} | `, errors);
}
